(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:Categories
   *
   * @description
   *
   */
  angular
  .module('neo.services')
  .factory('Categories', Categories);

  function Categories($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/public/categories');
  }
}());
